<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="roleData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Modules.User.Label.Account') }}</span>
        </template>
        <role-edit-tab
          :role-data="roleData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import RoleEditTab from './roleEditTab.vue'
import RoleRepository from '@/modules/user/repository/roleRepository'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    RoleEditTab
  },
  setup() {
    const roleData = ref(null)
    RoleRepository.view(router.currentRoute.params.id)
      .then(response => {
        roleData.value = response.data.data;
        if(roleData.value.permissions == null) roleData.value.permissions = {};
      })
      .catch(error => {
        if (error.response.status === 404) {
          roleData.value = undefined
        }
      })
    return {
      roleData,
    }
  },
}
</script>

<style>

</style>
