<template>
  <div>
    <!-- User Info: Input Fields -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
      >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>

          <!-- Field: Title -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Role.Label.Role')"
              rules="required"
            >
              <b-form-group
                :label="$t('Modules.Role.Label.Role')"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="roleDataObject.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Icon -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- Icon -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Role.Label.Icon')"
              rules="required"
            >
              <b-form-group
                :label="$t('Modules.Role.Label.Icon')"
                label-for="icon"
              >
                <b-form-input
                  id="icon"
                  v-model="roleDataObject.icon"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Variant -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Role.Label.Variant')"
              rules="required"
            >
              <b-form-group
                :label="$t('Modules.Role.Label.Variant')"
                label-for="variant"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="roleDataObject.variant"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleVariants"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="variant"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- PERMISSION TABLE -->
        <b-card
          no-body
          class="border mt-1"
          v-show="!roleDataObject.isSupper"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('Permission') }}</span>
            </b-card-title>
          </b-card-header>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="roleDataObject.permissionsData"
            :fields="permissionsFields"
          >
            <template #cell(module_label)="data">
              {{ data.value }}
            </template>
            <template #cell()="data">
              <b-form-checkbox :checked="data.value" v-model="roleDataObject.permissions[data.item.module_name + '.' + data.field.key]" />
            </template>
          </b-table>
        </b-card>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('Cancel') }}
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormInvalidFeedback
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import RoleRepository from '@/modules/user/repository/roleRepository'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    roleData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, context) {
    const vm = context.root
    const permissionsFields = [
      {
        key: 'module_label',
        label: vm.$i18n.t('Module'),
        sortable: false
      },
      {
        key: 'read',
        label: vm.$i18n.t('Read'),
        sortable: false
      },
      {
        key: 'write',
        label: vm.$i18n.t('Write'),
        sortable: false
      },
      {
        key: 'create',
        label: vm.$i18n.t('Create'),
        sortable: false
      },
      {
        key: 'delete',
        label: vm.$i18n.t('Delete'),
        sortable: false
      }
    ]
    const roleVariants = [
      { label: 'Primary', value: 'primary' },
      { label: 'Secondary', value: 'secondary' },
      { label: 'Success', value: 'success' },
      { label: 'Warning', value: 'warning' },
      { label: 'Danger', value: 'danger' },
      { label: 'Info', value: 'info' },
    ]

    const roleDataObject = ref(JSON.parse(JSON.stringify(props.roleData)))
    const resetroleData = () => {
      roleDataObject.value = JSON.parse(JSON.stringify(props.roleData))
    }
    const onSubmit = () => {
      RoleRepository.update(roleDataObject.value)
        .then(() => {})
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetroleData)

    return {
      permissionsFields,
      roleVariants,
      roleDataObject,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
